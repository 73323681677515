import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import Banner from "./Banner";

const BlogTagSearch = () => {
  const { tag } = useParams(); // Extract the tag from the URL
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Fetch blogs by tag
    const fetchBlogsByTag = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://blog-backend-ivfv.onrender.com/blogs/tag/${tag}`
        );
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs by tag:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogsByTag();
  }, [tag]); // Re-run whenever the tag changes

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <Banner />
      <div className="blogwrapper blogtagpagecss container ">
        <h3 className="ml-20">Blogs tagged with "{tag}"</h3>
        <div className="row row-cols-1 row-cols-md-2 mt-5 row-cols-lg-3 g-4">
          {blogs.length > 0 ? (
            blogs.map((blog, id) => (
              <div key={id} className="col-md-4 mb-4">
                <div className="card  max-height: 100% border-0 shadow-sm">
                  <img
                    src={`https://blog-backend-ivfv.onrender.com/${blog.thumbnail}`}
                    className="card-img-top"
                    alt="blog thumbnail"
                  />

                  <div className="card-body">
                    <span className="badgee bg-dark mb-2">{blog.category}</span>
                    <span className="datewrapper">
                      {moment(blog.createdAt).format("MMM D, YYYY")}
                    </span>
                    <h5 className="hovertitle">
                      <Link
                        to={`/blogs/${blog.slug}`}
                        className="text-decoration-none text-dark hover"
                      >
                        {blog.title}
                      </Link>
                    </h5>
                    {/* <p className="card-text text-muted">{blog.summary}</p> */}
                  </div>
                  <div className="card-footer border-0">
                    <div className="d-flex justify-content-between align-items-center">
                      {/* <small className="text-muted">
                                    By{" "}
                                    {blog.author && blog.author.name
                                      ? blog.author.name
                                      : "Anonymous"}
                                  </small> */}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-12">
              <div className="alert alert-warning">No blogs found</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogTagSearch;
