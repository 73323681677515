/* eslint-disable react/jsx-pascal-case */
// import logo from "./logo.svg";
import "./App.css";
import Header from "./Header";
import Home from "./Home";
import Footer from "./Footer1";
import About from "./About";
import Action from "./Action";
import Java from "./Java";
import Reactjs from "./Reactjs";
import Android from "./Android";
import Dotnet from "./Dotnet";
import Node from "./Node";
import Native from "./Native";
import Digital_Marketing from "./Digital_Marketing";
import SEO from "./SEO";
import PHP from "./PHP";
import Angular from "./Angular";
import ResourceSolution from "./Resource";
import Internship from "./Intern";
import IOS from "./IOS";
import RPA from "./RPA";
import SAPIBP from "./SAPIBP";
import SAPHANA from "./SAPHANA";
import SAPMonitoring from "./SAPMonitoring";
import Engineering from "./Engineering";
import SAPBusiness from "./SAPbusiness";
import SAPCloud from "./SAPCloud";
import Life from "./Life";
import Carrier from "./Carrier";
import Components from "./Components";
import Project from "./Project";
import Customer from "./Customer";
import Pharma from "./Pharma";
import Semiconductor from "./Semiconductor";
import History from "./History";
import Wordpress from "./Wordpress";
import Services from "./Services";
import Contact from "./Contact";
import Career from "./Career.js";
import Login from "./Login.js";

import { Route, Routes } from "react-router-dom";
import Blogs from "./blogscomponents/Blogs.js";
import BlogDetails1 from "./Blogdetails1.js";
import Modal from "../src/Modal";
import Details from "./Details.js";
import Blogdetails2 from "./Blogdetails2.js";
import Blogdetails3 from "./Blogdetails3.js";
import Blogdetails4 from "./Blogdetails4.js";
import Blogdetails5 from "./Blogdetails5.js";
import Blogdetails6 from "./Blogdetails6.js";
import Blogdetails7 from "./Blogdetails7.js";
import Blogdetails8 from "./Blogdetails8.js";
import Blogdetails9 from "./Blogdetails9.js";
import ForgotPassword from "./ForgotPassword.js";
import CreateAccount from "./CreateAccount.js";
import CareerLanding from "./CareerLanding.js";
import ApplyForm from "./ApplyForm.js";
import IndustryLanding from "./IndustryLanding.js";
import Products from "./Products.js";
import Tabbing from "./Tabbing.js";
import Barde from "./Barde.js";
import TCApp from "./TCApp.js";
import BestowalWeb from "./BestowalWeb.js";
import ECommerce from "./ECommerce.js";
import GoogleLogin from "./blogscomponents/GoogleLogin.js";
import CommentSection from "./blogscomponents/CommentSection.js";
import BlogDetails from "./blogscomponents/BlogDetails.js";
import RelatedBlogSearch from './blogscomponents/ReBlog.js'
import BlogTagSearch from './blogscomponents/BlogTagsearch.js'


function App() {


  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/action" element={<Action />} />
        <Route path="/java" element={<Java />} />
        <Route path="/reactjs" element={<Reactjs />} />
        <Route path="/android" element={<Android />} />
        <Route path="/dotnet" element={<Dotnet />} />
        <Route path="/node" element={<Node />} />
        <Route path="/native" element={<Native />} />
        <Route path="/digital" element={<Digital_Marketing />} />
        <Route path="/seo" element={<SEO />} />
        <Route path="/php" element={<PHP />} />
        <Route path="/angular" element={<Angular />} />
        <Route path="/resource" element={<ResourceSolution />} />
        <Route path="/intern" element={<Internship />} />
        <Route path="/ios" element={<IOS />} />
        <Route path="/rpa" element={<RPA />} />
        <Route path="/sap" element={<SAPIBP />} />
        <Route path="/saphana" element={<SAPHANA />} />
        <Route path="/monitoring" element={<SAPMonitoring />} />
        <Route path="/cloud" element={<SAPCloud />} />
        <Route path="/sapbusiness" element={<SAPBusiness />} />
        <Route path="/engineering" element={<Engineering />} />
        <Route path="/life" element={<Life />} />
        <Route path="/career" element={<Carrier />} />
        <Route path="/modal" element={<Modal />} />
        {/* <Route path="/jobs/:jobId" element={<Details />} /> */}
        <Route path="/details/:jobId" element={<Details />} />
        <Route path="/electronics" element={<Components />} />
        <Route path="/chemical" element={<Project />} />
        <Route path="/supply" element={<Customer />} />
        <Route path="/pharma" element={<Pharma />} />
        <Route path="/semiconductor" element={<Semiconductor />} />
        <Route path="/history" element={<History />} />
        <Route path="/wordpress" element={<Wordpress />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/blogdetails1" element={<BlogDetails1 />} />
        <Route path="/careers" element={<Career />} />
        {/* <Route path="/Details" element={<Details />} /> */}
        <Route path="/blogdetails2" element={<Blogdetails2 />} />
        <Route path="/blogdetails3" element={<Blogdetails3 />} />
        <Route path="/blogdetails4" element={<Blogdetails4 />} />
        <Route path="/blogdetails5" element={<Blogdetails5 />} />
        <Route path="/blogdetails6" element={<Blogdetails6 />} />
        <Route path="/blogdetails7" element={<Blogdetails7 />} />
        <Route path="/blogdetails8" element={<Blogdetails8 />} />
        <Route path="/blogdetails9" element={<Blogdetails9 />} />
        <Route path="/careerlanding" element={<CareerLanding />} />
        <Route path="/google-login" element={<GoogleLogin />} />
        <Route path="comments" element={<CommentSection />} />
        <Route path="/blogs/:slug" element={<BlogDetails />}>
          <Route path="comments" element={<CommentSection />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/createaccount" element={<CreateAccount />} />
        <Route path="/applicationform" element={<ApplyForm />} />
        <Route path="/industrylanding" element={<IndustryLanding />} />
        <Route path="/products" element={<Products />} />
        <Route path="/tabbing" element={<Tabbing />} />
        <Route path="/barde" element={<Barde />} />
        <Route path="/TCApp" element={<TCApp />} />
        <Route path="/Bestowal" element={<BestowalWeb />} />
        <Route path="/e-commerce" element={<ECommerce />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route path="/relatedblog/:slug" element={<RelatedBlogSearch />} />
        <Route path="/blogs/category/:tag" element={<BlogTagSearch />} />
      </Routes>
      <Footer />

    </>
  );
}

export default App;
