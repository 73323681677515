import BlogMainPage from "./BlogMainPage";

const Blogs = () => {
  return (
    <>
      <div className="blogpage">
        <BlogMainPage />
      </div>
    </>
  );
};

export default Blogs;
